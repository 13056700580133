import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import TestimonialSliderSlide from './TestimonialSliderSlide'
import sliderArrow from '../../../../../images/slider-arrow.png'
import tw from 'tailwind.macro'
import 'swiper/swiper.min.css'

const defaultParams = {
  centeredSlides: true,
  navigation: null,
  spaceBetween: 100,
  loop: false,
  autoplay: false,
  slidesPerView: 1,
  pagination: {
    el: '.swiper-dots',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: '.arrow-next',
    prevEl: '.arrow-prev',
  },
}

const Inner = styled.div`
  max-width: 1000px;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${tw`
    bg-light-grey-1
    xl:mx-0
    px-3
`}
`

const ArrowLeft = styled.div`
  ${tw`
    cursor-pointer
    xl:pr-3
    hidden
    lg:flex
    items-center
    justify-start
    opacity-50
    `}

  &.swiper-button-disabled {
    ${tw`
        opacity-25
      `}
  }

  height: 50px;
  width: 50px;
  img {
    height: 37px;
    min-width: 20px;
  }
`

const ArrowRight = styled.div`
  ${tw`
    cursor-pointer
    xl:pl-3
    hidden
    lg:flex
    items-center
    justify-end
    opacity-50
    `}

  &.swiper-button-disabled {
    ${tw`
        opacity-25
      `}
  }

  height: 50px;
  width: 50px;
  img {
    height: 37px;
    min-width: 20px;
    transform: rotate(180deg);
  }
`

const SwiperDots = styled.div`
  ${tw`
    flex
    mt-0
    justify-center
    flex
    lg:hidden
    pb-4
  `}

  .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #d1506a;
    opacity: 0.5;
    border-radius: 50%;

    &.swiper-pagination-bullet-active {
      background: #b72375;
      opacity: 1;
    }
  }
`

function TestimonialSlider({ items, customParams, onSlideChange, slide }) {
  const instanceParams = {
    ...defaultParams,
    ...customParams,
  }

  return items && items.length ? (
    <Container>
      <ArrowLeft className="arrow-prev">
        <img src={sliderArrow} />
      </ArrowLeft>
      <Inner>
        <Swiper
          {...instanceParams}
          onSlideChange={(swiper) => {
            onSlideChange(swiper)
          }}
          initialSlide={slide || 0}
        >
          {items.map((slide, index) => (
            <SwiperSlide>
              <TestimonialSliderSlide key={index} {...slide} />
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperDots className="swiper-dots" />
      </Inner>
      <ArrowRight className="arrow-next">
        <img src={sliderArrow} />
      </ArrowRight>
    </Container>
  ) : null
}

export default TestimonialSlider
