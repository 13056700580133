import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import { screen } from '../../../../../utils'
import icon from '../../../../../images/icon-quote.png'
import classnames from 'classnames'

const ContainerContent = styled.div`
  ${tw`
    -mx-6
    pt-4
    pb-0
    md:pb-6
    md:pt-2
  `}

  ${screen('lg')} {
    margin: 0 auto;
  }

  ${screen('xl')} {
    margin: 0 auto;
  }
`

const Inner = styled.div`
  ${tw`
    mx-auto
    my-0
    block
    md:flex
  `}
`

const Image = styled.img`
  ${tw`
    flex
    md:mx-3
    md:my-auto
    rounded-full
  `}
  min-height: 128px;
  min-width: 128px;
  max-height: 128px;
  max-width: 128px;
  object-fit: cover;
  margin: 0 auto 24px;
`

const TextContainer = styled.div`
  ${tw`
    font-sans
    text-charcoal
    px-3
    pt-2
    md:pt-5
    `}
`
const IconContainerMobile = styled.img`
  ${tw`
    w-16
    inline-block 
    float-left
    md:my-0
    mr-2
    mb-1
    md:hidden
  `}
  object-fit: contain;
`

const IconContainer = styled.img`
  ${tw`
     w-18
     h-16
     flex
     md:my-auto
     mx-3
     invisible
     md:visible
    `}

  ${screen('md')} {
    visibility: visible;
  }
`

const Quotecontainer = styled.div`
  ${tw`
  
  `}
`

const QuoteOrder = styled.div`
  ${tw`
  flex
  flex-col
  md:flex-row
  `}
`

function TestimonialSliderSlide({ image, title, quote, author, position }) {
  return (
    <ContainerContent>
      <Inner>
        <Image src={image} />
        <QuoteOrder>
          <TextContainer>
            <div
              className={classnames(
                'text-4xl',
                'text-black-trans-light',
                'font-bold',
                'pb-2',
                'md:pb-3',
                'text-center',
                'md:text-left'
              )}
            >
              {title}
            </div>
            <div
              className={classnames(
                'leading-relaxed',
                'text-lg',
                'font-light',
                'pb-0',
                'md:pb-5'
              )}
            >
              <Quotecontainer>
                <p className="pb-2 lg:pb-3">
                  <IconContainerMobile src={icon} />
                  {quote}
                </p>
              </Quotecontainer>

              <div
                className={classnames(
                  'text-right',
                  'leading-relaxed',
                  'text-lg',
                  'font-light'
                )}
                dangerouslySetInnerHTML={{
                  __html: `${author}${position ? `, ${position}` : null}`,
                }}
              />
            </div>
          </TextContainer>
          <IconContainer src={icon} />
        </QuoteOrder>
      </Inner>
    </ContainerContent>
  )
}

export default TestimonialSliderSlide
